// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-index-js": () => import("/opt/build/repo/src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-contact-success-js": () => import("/opt/build/repo/src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-articles-autonomous-travel-index-mdx": () => import("/opt/build/repo/src/pages/articles/autonomous-travel/index.mdx" /* webpackChunkName: "component---src-pages-articles-autonomous-travel-index-mdx" */),
  "component---src-pages-articles-shaders-index-mdx": () => import("/opt/build/repo/src/pages/articles/shaders/index.mdx" /* webpackChunkName: "component---src-pages-articles-shaders-index-mdx" */),
  "component---src-pages-articles-photoeditor-index-mdx": () => import("/opt/build/repo/src/pages/articles/photoeditor/index.mdx" /* webpackChunkName: "component---src-pages-articles-photoeditor-index-mdx" */),
  "component---src-pages-articles-mood-index-mdx": () => import("/opt/build/repo/src/pages/articles/mood/index.mdx" /* webpackChunkName: "component---src-pages-articles-mood-index-mdx" */),
  "component---src-pages-articles-us-iran-index-mdx": () => import("/opt/build/repo/src/pages/articles/us-iran/index.mdx" /* webpackChunkName: "component---src-pages-articles-us-iran-index-mdx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

